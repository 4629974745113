import dev from "../../assets/images/dev.png";
import devfb from "../../assets/images/dev.jpg";

const SocialMedia = (prop) => {
  return (
    <div className="min-vh-100 text-white bg-dark d-flex flex-column  py-5 ">
      {prop.lang == "english" && <div>
        <div  data-aos ="fade-up" className="display-4">My Social media</div>
        <div data-aos ="fade-up"className="h4">you can find me here</div>
      </div> }

      {prop.lang != "english" && (
        <>
          <div  data-aos ="fade-up" className="display-4">مواقع التواصل الاجتماعي</div>
          <div data-aos ="fade-up" className="h4">رح تلقاني هنا</div>
          
          </>
      )}

      <div className="d-flex flex-wrap justify-content-center   align-items-center  m-5 py-5 gap-2 ">
        <div
          className="bg-black bg-opacity-25 scale-on-hover glow-shadow-blue glow-shadow-blue p-3 border-1 shadow-blue "
          onClick={() => {
            window.location =
              "https://www.instagram.com/t.h.e.d.e.v.e.l.o.p.e.r/";
          }}
          style={{
            width: "300px",
            borderColor: "gold",
            borderStyle: "solid",
          }}
        >
          <div className="d-flex text-start align-items-center gap-2">
            <div className="position-relative">
              <img height={"90px"} src={dev} />
              <img
                className="position-absolute bottom-0 start-0"
                height={"25px"}
                src="https://img.icons8.com/?size=100&id=32323&format=png&color=000000"
              />
            </div>
            <div>
              <div className="">Instagram</div>
              <div className="">t.h.e.d.e.v.e.l.o.p.e.r</div>
              <b className="px-1">1k+</b>
            </div>
          </div>
        </div>
        <div
          className="bg-black bg-opacity-25 scale-on-hover glow-shadow-blue glow-shadow-blue p-3 border-1 shadow-blue "
          style={{ width: "300px", borderColor: "gold", borderStyle: "solid" }}
          onClick={() => {
            window.location = "https://www.tiktok.com/@thedevstuff";
          }}
        >
          <div className="d-flex text-start align-items-center gap-2">
            <div className="position-relative">
              <img height={"90px"} src={dev} />
              <img
                className="position-absolute bottom-0 start-0"
                height={"25px"}
                src="https://img.icons8.com/?size=100&id=118640&format=png&color=000000"
              />
            </div>{" "}
            <div>
              <div>tiktok</div>
              <div>thedevstuff</div>
              <b className="px-1">3k+</b>
            </div>
          </div>
        </div>
        <div
          className="bg-black bg-opacity-25 scale-on-hover glow-shadow-blue p-3 border-1 shadow-blue "
          style={{
            width: "300px",
            borderColor: "gold",
            borderStyle: "solid",
          }}
          onClick={() => {
            window.location =
              "https://www.facebook.com/t.h.e.d.e.v.l.o.p.e.r.r";
          }}
        >
          <div className="d-flex text-start align-items-center gap-2">
            <div className="position-relative">
              <img height={"90px"} className="rounded-circle" src={devfb} />
              <img
                className="position-absolute  bottom-0 start-0"
                height={"25px"}
                src="https://img.icons8.com/?size=100&id=118497&format=png&color=000000"
              />
            </div>{" "}
            <div>
              <div> Facebook</div>
              <div>t.h.e.d.e.v.l.o.p.e.r.r</div>
              <b className="px-1">7k+</b>
            </div>
          </div>
        </div>

        <div
          className="bg-black bg-opacity-25 scale-on-hover glow-shadow-blue p-3 border-1 shadow-blue "
          style={{
            width: "300px",
            borderColor: "gold",
            borderStyle: "solid",
          }}
          onClick={() => {
            window.location =
              "https://www.facebook.com/profile.php?id=100087924852024";
          }}
        >
          <div className="d-flex text-start align-items-center gap-2">
            <div className="position-relative">
              <img height={"90px"} src={dev} />
              <img
                height={"25px"}
                className="position-absolute bottom-0 start-0"
                src="https://img.icons8.com/?size=100&id=118497&format=png&color=000000"
              />
            </div>{" "}
            <div>
              <div> Facebook</div>
              <div>thedev</div>
              <b className="px-1" style={{ fontSize: "11px" }}>
                newly created
              </b>
            </div>
          </div>
        </div>
      </div>

      {prop.lang == "english" && <div>
        <div className="h2 text-light" data-aos ="fade-up" data-aos-delay="500">we are waiting for your message</div>
        <div className="h4" data-aos ="fade-up"  data-aos-delay="600">don't hesitate</div>
      </div> }

      {prop.lang != "english" && (
        <>
       <div className="h2 text-light" data-aos ="fade-up" data-aos-delay="500">رانا نستناو فيكم</div>
       <div className="h4" data-aos ="fade-up"  data-aos-delay="600">مرحبا بيكم</div>
          </>
      )}
    </div>
  );
};
export default SocialMedia;
