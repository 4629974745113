import { useGlitch } from "react-powerglitch";

const SkillCard = (prop) => {
  const g = useGlitch();
  return (
    <div
      onClick={prop.onClick}
      className={
        (prop.flipped ? "shadow-blue " : " ") +
        "p-1 skill-card  text-start border-1     m-2  " +
        prop.className
      }
      style={{
        borderColor: "gold",
        borderStyle: "solid",
        height: "400px",
        width: "250px",
      }}
    >
      <div className="h-100 w-100 position-relative ">
        <div
          className="h-100 w-100  position-absolute rounded-4 d-flex justify-content-center text-center  align-items-center display-1"
          style={{
            transform: "rotateY(180deg)",
            color: "#ff550040",
            fontWeight: "bolder",
            background:
              "radial-gradient(circle, rgba(255,215,0,1) 0%, rgba(255,184,0,1) 100%)",
            opacity: prop.flipped ? "1" : "0",
          }}
        >
          <div ref={g.ref}>
            {" "}
            {prop.lang == "en" ? "Skill Card" : "بطاقة مهارة"}
          </div>
        </div>
        <div className="h-100 w-100 p-3">
          <div className="py-3 color-violet h2"> {prop.name} </div>
          {prop.properties.map((el, k) => (
            <div className="d-flex  justify-content-between">
              <span>{el.name}</span>
              <span
                className="text-end"
                style={{
                  color:
                    el.level == "intermidiate" || el.level == "متوسط"
                      ? "#ffff00"
                      : el.level == "low" || el.level == "منخفظ"
                      ? "#ff0000"
                      : el.level == "high" || el.level == "مرتفع"
                      ? "#00ff00"
                      : "",
                }}
              >
                {el.level}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default SkillCard;
