import Projectmini from "./in compnents/Projectmini";

import yourstore1 from "../../assets/screenshots/web1/web (1).png";
import yourstore2 from "../../assets/screenshots/web1/web (2).png";
import yourstore3 from "../../assets/screenshots/web1/web (3).png";
import yourstore4 from "../../assets/screenshots/web1/web (4).png";
import yourstore5 from "../../assets/screenshots/web1/web (5).png";
import yourstore6 from "../../assets/screenshots/web1/web (6).png";
import yourstore7 from "../../assets/screenshots/web1/web (7).png";
import yourstore8 from "../../assets/screenshots/web1/web (8).png";
import yourstore9 from "../../assets/screenshots/web1/web (9).png";
import yourstore10 from "../../assets/screenshots/web1/web (10).png";


import watch1 from "../../assets/screenshots/web2/web (1).png";
import watch2 from "../../assets/screenshots/web2/web (2).png";
import watch3 from "../../assets/screenshots/web2/web (3).png";
import watch4 from "../../assets/screenshots/web2/web (4).png";
import watch5 from "../../assets/screenshots/web2/web (5).png";


import yourstoreapp1 from "../../assets/screenshots/app1/a (1).png";
import yourstoreapp2 from "../../assets/screenshots/app1/a (2).png";
import yourstoreapp3 from "../../assets/screenshots/app1/a (3).png";
import yourstoreapp4 from "../../assets/screenshots/app1/a (4).png";
import yourstoreapp5 from "../../assets/screenshots/app1/a (5).png";
import yourstoreapp6 from "../../assets/screenshots/app1/a (6).png";
import yourstoreapp7 from "../../assets/screenshots/app1/a (7).png";
import yourstoreapp8 from "../../assets/screenshots/app1/a (8).png";

const ProjectsPreview = (prop) => {
  const examples =[
    { link: "https://e-com-859d0.web.app/", title: "موقع البسة" },
    { link: "https://accessories-e-com.web.app/", title: "موقع اكسسوارات" },

  ];
  if (prop.lang == "english") {
    return (
      <div id="2" className="min-vh-100 bg-dark py-4">
        <div
          data-aos-delay="200"
          data-aos="fade-up"
          className="display-3 text-white"
        >
          what do we offer
        </div>
        <div
          data-aos-delay="400"
          data-aos="fade-up"
          className="h4 text-white "
          style={{ fontWeight: "normal" }}
        >
          if you work with us{" "}
        </div>

        <div
          data-aos-delay="500"
          data-aos="fade-up"
          className="d-flex justify-content-around align-items-center gap-3 p-md-5 flex-wrap"
        >
          <Projectmini
            lang={prop.lang}
            screenshots={[
              yourstore4,
              yourstore5,
              yourstore6,

              yourstore7,
              yourstore8,
              yourstore9,
              yourstore10,
              yourstore2,
              yourstore3,
              yourstore1,
              watch1,
              watch2,
              watch3,
              watch4,
              watch5,
            ]}
            title="e-com websites"
            disc={`a website that can help you: \n *make you business look more authentic \n *help you generate more orders \n *handle orders. \n `}
            examples={examples}
          ></Projectmini>
          <Projectmini
            lang={prop.lang}
            screenshots={[
              yourstoreapp1,
              yourstoreapp2,
              yourstoreapp3,
              yourstoreapp4,
              yourstoreapp5,
              yourstoreapp6,
            ]}
            title="e-com apps"
            disc={`Our web sites can be crossplateform ...\nwhich means we can help you transform them to : \n *android app \n *windows app \n *linux app  `}
          ></Projectmini>
        </div>
        <div className="d-flex align-items-center w-100 justify-content-center">
          <div
            onClick={() => {
              window.location.href = "#3";
            }}
            style={{ height: "40px", width: "40px", bottom: "0px" }}
            className="bg-light d-flex align-items-center justify-content-center  rounded-circle "
          >
            <lord-icon
              src="https://cdn.lordicon.com/rmkahxvq.json"
              trigger="loop"
            ></lord-icon>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div id="2" dir="rtl" className="min-vh-100 bg-dark py-4">
        <div
          data-aos="fade-up"
          data-aos-delay="200"
          className="display-3 text-white"
        >
          وش راح نوفرولك؟
        </div>
        <div
          data-aos="fade-up"
          data-aos-delay="400"
          className="h4 text-white "
          style={{ fontWeight: "normal" }}
        >
          كي تخدم معانا{" "}
        </div>

        <div
          data-aos="fade-up"
          data-aos-delay="500"
          className="d-flex justify-content-around align-items-center gap-3 p-md-5 flex-wrap"
        >
          <Projectmini
            lang={prop.lang}
            screenshots={[
              yourstore4,
              yourstore5,
              yourstore6,
              yourstore7,
              yourstore8,
              yourstore9,
              yourstore10,
              yourstore2,
              yourstore3,
              yourstore1,
              watch1,
              watch2,
              watch3,
              watch4,
              watch5,]}
            title="موقع للتجارة الالكترونية"
            disc={`موقع خاص الذي سيساعدك في: \n *جعل عملك يبدو أكثر مصداقية و احترافية \n *الحصول على المزيد من الطلبيات \n *التعامل و ترتيب الطلبات. \n `}
            examples={examples}
          ></Projectmini>
          <Projectmini
            lang={prop.lang}
            screenshots={[
              yourstoreapp1,
              yourstoreapp2,
              yourstoreapp3,
              yourstoreapp4,
              yourstoreapp5,
              yourstoreapp6,
            ]}
            title="تطبيقات للتجارة الاكترونية"
            disc={`المواقع التي نوفرها يمكن ان تتحول لتطبيقات\n متعددة المنصات...\nمما يعني اننا يمكنا مساعدتك في تحويلها الى: \n *android app \n *windows app \n *linux app  `}
          ></Projectmini>
        </div>
        <div className="d-flex align-items-center w-100 justify-content-center">
          <div
            onClick={() => {
              window.location.href = "#3";
            }}
            style={{ height: "40px", width: "40px", bottom: "0px" }}
            className="bg-light d-flex align-items-center justify-content-center  rounded-circle "
          >
            <lord-icon
              src="https://cdn.lordicon.com/rmkahxvq.json"
              trigger="loop"
            ></lord-icon>
          </div>
        </div>
      </div>
    );
  }
};
export default ProjectsPreview;
