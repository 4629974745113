import bg from "./assets/bg/bg3.mp4";
import "./App.css";
import LandingPage from "./views/LandingPage";
import "bootstrap/dist/css/bootstrap.min.css";
import WebsiteNavbar from "./components/WebsiteNavbar";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
import { useEffect } from "react";
import "primereact/resources/themes/mdc-dark-deeppurple/theme.css";
import { Helmet } from "react-helmet";
import { get, ref, set, update } from "firebase/database";
import database from "./Services/FirebaseDatabase";
import { getDate } from "./utils";

function App() {
  useEffect(() => {
    AOS.init();

    const dbRef = new ref(database, "/visitors");

    fetch(
      "https://api.ipdata.co/?api-key=f9e6ee952686b8cdcdd8e1c58545ccaa4dc34cf09e512b6c0f9b06ea"
    )
      .then((response) => response.json())
      .then((responseJson) => {
        let new_visitor = {};
        

        get(
          ref(database, "visitors/" + (responseJson.ip.split(".") + " "))
        ).then((res) => {
          if (res.exists()&&res.val().visits!= null) {
            
            let visits = Object.values(res.val().visits);
            visits.push({time:responseJson.time_zone["current_time"]})
            new_visitor[responseJson.ip.split(".") + " "] = {
              ...responseJson,
              ...{ visits: visits },
            };
            
          }else{
            new_visitor[responseJson.ip.split(".") + " "] = {
              ...responseJson,
              ...{ visits: [{time:responseJson.time_zone["current_time"]}]},
            };
          }
          update(dbRef, new_visitor)
            .then((err) => {
              console.log(err);
            })
            .catch((err) => {
              console.log(err);
            });
        });
      });
  }, []);
  return (
    <div className="App">
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, user-scalable=no, maximum-scale=1, shrink-to-fit=no"
        />
      </Helmet>
      <div id="v-holder" className="vw-100 overflow-x-hidden">
        <video autoPlay muted loop play playsInline id="myVideo">
          <source src={bg} type="video/mp4" />
        </video>
      </div>

      <LandingPage />
    </div>
  );
}

export default App;
