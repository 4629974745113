import { useEffect } from "react";

const useScrollExceeds = (exceedY, setScrollExceeds) => {
  useEffect(() => {
    const callOnScrollEnd = () => {
      setScrollExceeds(window.scrollY >= exceedY);
    };

    document.addEventListener("scrollend", callOnScrollEnd);

    return () => {
      document.removeEventListener("scrollend", callOnScrollEnd);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useScrollExceeds;
