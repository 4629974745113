import { useEffect } from "react";

const ShowImage = (prop) => {
  useEffect(()=>{
    window.location.href ="#2"
  })
  return (
    <div
    id="img"
      style={{ opacity: (prop.image == null ? "0" : "1" ), zIndex: "8" }}
      className="position-fixed d-flex justify-content-center  start-0 top-0 vh-100 vw-100 bg-black bg-opacity-25"
    >
      <div
        style={{ height: "75%", width: "fit-content" }}
        className="position-relative "
      >
        <img
          src={prop.image}
          className="shadow rounded-5"
          style={{ maxHeight: "100%", maxWidth: "100%", zIndex: "8" }}
        />
        <div
          onClick={prop.click}
          className="bg-danger z-3 position-absolute top-0 translate-middle shadow user-select-none start-0 rounded-circle"
          style={{ height: "30px", width: "30px" }}
        >
          x
        </div>
      </div>
    </div>
  );
};
export default ShowImage;
