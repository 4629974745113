import { useRef, useState } from "react";
import ChooseLang from "../ChooseLang";
import SkillCard from "./in compnents/SkillCard";
import { useGlitch } from "react-powerglitch";

const Skills = (props) => {
  const g = useGlitch();
  const [i, setIndex] = useState(-1);
  if (props.lang == null || props.lang == "english") {
    return (
      <div className=" min-vh-100 py-4 px-3 text-white rounded-top-5 bg-dark  vw-100">
        <ChooseLang
          value={props.value}
          onChange={props.selectLang}
          options={["english", "عربي"]}
        />
        <div
          
          className="display-5  p-0 m-0"
          style={{ color:"gray!important" }} 
          data-aos="zoom-in"
        >
          My skills:
        </div>
        <div className=" position-relative  ">
          <div
            className="skill-card rounded-4 d-flex align-items-center justify-content-center  text-opacity-50"
            style={{
              backgroundColor: "#44444460",
              height: "400px",
              width: "250px",
              transform: "rotate(0deg)!important",
            }}
          ></div>
          <div
            className="skill-card not-flipped rounded-4 h1 d-flex align-items-center justify-content-center  text-opacity-50"
            style={{
              backgroundColor: "#44444460",
              height: "400px",
              width: "250px",
              color: "gray",
            }}
          >
            {" "}
            flip the cards
          </div>

          <SkillCard
            lang="en"
            onClick={() => {
              let n = -1;
              if (i > 4) n = -1;
              else if (i < 4) n = i + 1;
              else if (i >= 4) n = i - 1;

              setIndex(n);
            }}
            flipped={i < 3}
            className={
              "rotate-3 " +
              (i >= 3 ? " not-flipped" : "") +
              (i == 3 ? "   z-3" : "")
            }
            name="languages"
            properties={[
              { name: "english", level: "high" },
              { name: "french", level: "intermidiate" },
              { name: "arabic", level: "high" },
            ]}
          />
          <SkillCard
            lang="en"
            onClick={() => {
              let n = -1;
              if (i > 4) n = -1;
              else if (i < 3) n = i + 1;
              else if (i >= 3) n = i - 1;

              setIndex(n);
            }}
            flipped={i < 3}
            className={
              "rotate-3 " +
              (i >= 3 ? " not-flipped" : "") +
              (i == 3 ? "   z-3" : "")
            }
            name="Other tools"
            properties={[
              { name: "Firebase", level: "high" },
              { name: "Cordova", level: "intermidiate" },
              { name: "dealing with APIs", level: "high" },
            ]}
          />
          <SkillCard
            lang="en"
            onClick={() => {
              let n = -1;
              if (i > 4) n = -1;
              else if (i < 2) n = i + 1;
              else if (i >= 2) n = i - 1;

              setIndex(n);
            }}
            flipped={i < 2}
            className={
              "rotate-n5 " +
              (i >= 2 ? " not-flipped" : "") +
              (i == 2 ? "  z-3" : "")
            }
            name="3D SKILLS"
            properties={[
              { name: "TREE.JS", level: "intermidiate" },
              { name: "Blender", level: "high" },
              { name: "cascadeur", level: "high" },
              { name: "Unity Game engine", level: "high" },
            ]}
          />
          <SkillCard
            lang="en"
            onClick={() => {
              let n = -1;

              if (i > 4) n = -1;
              else if (i < 1) n = i + 1;
              else if (i >= 1) n = i - 1;

              setIndex(n);
            }}
            flipped={i < 1}
            className={
              "rotate-3 " +
              (i >= 1 ? " not-flipped" : "") +
              (i == 1 ? "  z-3" : "")
            }
            name="Soft skills"
            properties={[
              { name: "Adobe photoshope", level: "high" },
              { name: "Adobe premiere", level: "high" },
              { name: "audio editings", level: "high" },
              { name: "microsoft office", level: "high" },
            ]}
          />
          <SkillCard
            lang="en"
            onClick={() => {
              let n = -1;

              if (i > 4) n = -1;
              else if (i < 0) n = i + 1;
              else if (i >= 0) n = i - 1;

              setIndex(n);
            }}
            flipped={i < 0}
            className={
              "rotate-5 " +
              (i >= 0 ? " not-flipped " : "") +
              (i == 0 ? "   z-3" : "")
            }
            name={"Programming"}
            properties={[
              { name: "html css js", level: "high" },
              { name: "react", level: "high" },
              { name: "angular", level: "intermidiate" },
              { name: "laravel", level: "intermediate" },
              { name: "python", level: "high" },
              { name: "c#", level: "high" },
              { name: "java", level: "high" },
            ]}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className=" min-vh-100 px-5 py-4  text-white rounded-top-5 bg-dark  vw-100">
        <ChooseLang
          value={props.value}
          onChange={props.selectLang}
          options={["english", "عربي"]}
        />
        <div  data-aos="zoom-in" className="display-4  p-0 m-0  " dir="rtl">
          مهاراتي
        </div>

        <div className=" position-relative p-0 m-0 ">
          <div
            className="skill-card rounded-4 d-flex align-items-center justify-content-center  text-opacity-50"
            style={{
              backgroundColor: "#44444460",
              height: "400px",
              width: "250px",
              transform: "rotate(0deg)!important",
            }}
          ></div>
          <div
            className="skill-card not-flipped rounded-4 h1 d-flex align-items-center justify-content-center  text-opacity-50"
            style={{
              backgroundColor: "#44444460",
              height: "400px",
              width: "250px",
              color: "gray",
            }}
          >
            {" "}
            اقلب الأوراق{" "}
          </div>

          <SkillCard
            onClick={() => {
              let n = -1;

              if (i > 4) n = -1;
              else if (i < 4) n = i + 1;
              else if (i >= 4) n = i - 1;
              setIndex(n);
            }}
            flipped={i < 4}
            className={
              "rotate-3 " +
              (i >= 4 ? " not-flipped" : "") +
              (i == 4 ? "   z-3" : "")
            }
            name="لغات"
            properties={[
              { name: "عربي", level: "مرتفع" },
              { name: "فرنسية", level: "مرتفع" },
              { name: "انجليزية", level: "مرتفع" },
            ]}
          />

          <SkillCard
            onClick={() => {
              let n = -1;

              if (i > 4) n = -1;
              else if (i < 3) n = i + 1;
              else if (i >= 3) n = i - 1;
              setIndex(n);
            }}
            flipped={i < 3}
            className={
              "rotate-3 " +
              (i >= 3 ? " not-flipped" : "") +
              (i == 3 ? "   z-3" : "")
            }
            name="أدوات اخرى"
            properties={[
              { name: "Firebase", level: "مرتفع" },
              { name: "Cordova", level: "متوسط" },
              { name: "dealing with APIs", level: "مرتفع" },
            ]}
          />

          <SkillCard
            onClick={() => {
              let n = -1;

              if (i > 4) n = -1;
              else if (i < 2) n = i + 1;
              else if (i >= 2) n = i - 1;
              setIndex(n);
            }}
            flipped={i < 2}
            className={
              "rotate-n5 " +
              (i >= 2 ? " not-flipped" : "") +
              (i == 2 ? "  z-3" : "")
            }
            name="3D مهارات"
            properties={[
              { name: "TREE.JS", level: "متوسط" },
              { name: "Blender", level: "مرتفع" },
              { name: "cascadeur", level: "مرتفع" },
              { name: "Unity Game engine", level: "مرتفع" },
            ]}
          />
          <SkillCard
            onClick={() => {
              let n = -1;
              if (i > 4) n = -1;
              else if (i < 1) n = i + 1;
              else if (i >= 1) n = i - 1;

              setIndex(n);
            }}
            flipped={i < 1}
            className={
              "rotate-3 " +
              (i >= 1 ? " not-flipped" : "") +
              (i == 1 ? "  z-3" : "")
            }
            name="البرامج:"
            properties={[
              { name: "Adobe photoshope", level: "مرتفع" },
              { name: "Adobe premiere", level: "مرتفع" },
              { name: "audio editings", level: "مرتفع" },
              { name: "microsoft office", level: "مرتفع" },
            ]}
          />
          <SkillCard
            onClick={() => {
              let n = -1;

              if (i > 4) n = -1;
              else if (i < 0) n = i + 1;
              else if (i >= 0) n = i - 1;

              setIndex(n);
            }}
            flipped={i < 0}
            className={
              "rotate-5 " +
              (i >= 0 ? " not-flipped " : "") +
              (i == 0 ? "   z-3" : "")
            }
            name={"البرمجة"}
            properties={[
              { name: "html css js", level: "مرتفع" },
              { name: "react", level: "مرتفع" },
              { name: "angular", level: "متوسط" },
              { name: "laravel", level: "متوسط" },
              { name: "python", level: "مرتفع" },
              { name: "c#", level: "مرتفع" },
              { name: "java", level: "مرتفع" },
            ]}
          />
        </div>
      </div>
    );
  }
};
export default Skills;
