import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyD8msBbTbdCHNF3vDxdrEQkaPrYnwqo2FY",
  authDomain: "thedev-22bb6.firebaseapp.com",
  databaseURL: "https://thedev-22bb6-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "thedev-22bb6",
  storageBucket: "thedev-22bb6.appspot.com",
  messagingSenderId: "176228689703",
  appId: "1:176228689703:web:c4ef674fe5361d1d40ab02",
  measurementId: "G-RV4VK4LGEE"
};
  const cong = initializeApp(firebaseConfig);
  const analytics = getAnalytics(cong);
 export default cong;