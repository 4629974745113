import { useState } from "react";
import ShowImage from "./ShowImage";
import { disableScroll, enableScroll } from "../../../utils";
const Projectmini = (prop) => {
  const [index, setIndex] = useState(0);
  const [img, setImg] = useState(null);
  
  return (
    <div
      className=" shadow text-white p-4 "
      style={{ height: "600px", maxWidth: "100vw!important", width: "475px" }}
    >
      <div className="h-50 d-flex overflow-hidden align-items-center justify-content-between">
        <div
          className="d-flex gap-1 align-items-center    justify-content-center h-100"
          style={{ width: "65%" ,maxHeight:"100%"}}
        >
          {prop.screenshots != null && (
            <img
              onClick={() => {
                setImg(prop.screenshots[index]);
                disableScroll();
              }}
              src={prop.screenshots[index]}
              className="scale-on-hover  p-3 rounded-5"
              style={{ maxHeight: "100%", maxWidth: "100%" }}
            />
          )}
        </div>
        <div
          className="d-flex gap-1 align-items-center  h-100 overflow-x-hidden overflow-y-scroll flex-wrap "
          style={{ width: "40%" }}
        >
          {prop.screenshots?.map((el, k) => (
            <img
              onClick={() => {
                setIndex(k);
              }}
              src={el}
              className="  carousel-item-h rounded-3"
              style={{ maxHeight: "80px", maxWidth: "70px" }}
            />
          ))}
        </div>
      </div>
      <div className={(prop.lang == "english" ? "text-start" : "text-end") + " h3 "}>
        {prop.title}
      </div>
      <div className={prop.lang == "english" ? "text-start" : "text-end"}>
        {prop.disc?.split("\n").map((str) => (
          <pre className="p-0 m-0">{str.replace("*", "    ✔️")}</pre>
        ))}
      </div>
      {prop.examples != null && prop.examples.length > 0 && (
        <div>check this live!</div>
      )}

      <div>
        {prop.examples?.map((el, k) => (
          <div>
            <a href={el.link}>{el.title}</a>
          </div>
        ))}
      </div>
      {img != null && (
        <ShowImage
          click={() => {
            setImg(null);
            enableScroll();
          }}
          image={img}
          
        />
      )}
    </div>
  );
};
export default Projectmini;
