import { useGlitch } from "react-powerglitch";
import { TypeAnimation } from "react-type-animation";
import profile_pic from "../assets/images/dev.png";
import Skills from "../components/LandingPage/Skills";
import { useEffect, useState } from "react";
import "./css/landing.css";
import ProjectsPreview from "../components/LandingPage/ProjectsPreview";
import SocialMedia from "../components/LandingPage/SocialMedia";
import useScrollExceeds from "../hooks/useScrollExceeds";
import WebsiteNavbar from "../components/WebsiteNavbar";
const LandingPage = () => {
  const glitch = useGlitch();
  const [lang, setLang] = useState("english");
  const [scrollExceeds, setScrollExceeds] = useState(false);

  useScrollExceeds(123, setScrollExceeds);

  useEffect(() => {
    console.log(lang);
  }, [lang]);
  return (
    <div>
      <WebsiteNavbar
        lang={lang}
        selectLang={(e) => {
          if (e.value != null) setLang(e.value);
        }}
        value={lang}
      />

      <div id="0" className=" position-relative vh-100 text-start p-4">
        {lang == "english" && (
          <div
            className="position-absolute top-50 translate-middle-y"
            style={{ maxHeight: "200px" }}
          >
            <h1
              data-aos-duration="2000"
              data-aos="fade-right"
              className="font-doctor-gliche color-blue display-5  glow-blue"
            >
              {" "}
              <div className="display-3">Welcom</div>
              <div className="d-flex gap-3  align-items-center">
                {" "}
                to my little <span ref={glitch.ref}>space</span>{" "}
                <span
                  className=" flicker-cursor"
                  style={{ fontFamily: "sans-serif" }}
                >
                  |
                </span>
              </div>
            </h1>

            <div
              data-aos-duration="1000"
              data-aos-delay="1000"
              data-aos="fade-right"
              className="font-tech color-violet glow-white display-1 bg-dark bg-opacity-75 d-flex align-items-center p-1 "
              style={{ width: "fit-content" }}
            >
              <TypeAnimation
                style={{ whiteSpace: "pre-line" }}
                wrapper="h1"
                speed={50}
                repeat={Infinity}
                sequence={[
                  "Hello there !",
                  3000,
                  "My name is ",
                  2000,
                  "My name is 🔸YACINE BALA ",
                  6000,
                  `And I will help you get your dream website!`,
                  3000,
                  `And I will help you get your dream website!\n  and only for a cheap price !!`,
                  7000,
                ]}
              ></TypeAnimation>
            </div>
          </div>
        )}

        {lang == "عربي" && (
          <div
            dir="rtl"
            className="position-absolute  top-50 end-0 text-end translate-middle-y"
            style={{ maxHeight: "200px" }}
          >
            <h1
              data-aos-duration="2000"
              data-aos="fade-right"
              className="font-teqniya color-blue display-5  glow-blue"
            >
              {" "}
              <div className="display-3">مرحبا</div>
              <div className="d-flex gap-3  align-items-center">
                {" "}
                الى <span ref={glitch.ref}>مساحتي</span> الصغيرة{" "}
                <span
                  className=" flicker-cursor"
                  style={{ fontFamily: "sans-serif" }}
                >
                  |
                </span>
              </div>
            </h1>

            <div
              data-aos-duration="1000"
              data-aos-delay="1000"
              data-aos="fade-right"
              className="font-origami color-violet-no-stroke glow-white border-light display-1 bg-dark bg-opacity-75 d-flex align-items-center p-1 "
              style={{ width: "fit-content" }}
            >
              <TypeAnimation
                style={{ whiteSpace: "pre-line" }}
                wrapper="h1"
                speed={50}
                repeat={Infinity}
                sequence={[
                  "مرحبا بيك !",
                  3000,
                  "اسمي ",
                  2000,
                  "اسمي 🔸 ياسين بالا ",
                  6000,
                  `و رح نعاونك باذن الله تخدم موقع على حسابك`,
                  3000,
                  `و رح نعاونك باذن الله تخدم موقع على حسابك!\n  بسومة هبال!!!!!`,
                  7000,
                ]}
              ></TypeAnimation>
            </div>
          </div>
        )}
        <div
          style={{ fontWeight: "bold" }}
          className="opacity-25 opacity-100-hover d-flex text-white gap-3 text-decoration-underline align-items-center px-5 py-2 position-absolute bottom-0 end-0"
          onClick={() => {
            window.location.href = "#3";
          }}
        >
          <img
            style={{ maxHeight: "2rem", maxWidth: "2rem" }}
            src={profile_pic}
          />
          <div>instagram</div>
          <div>facebook</div>
          <div> tiktok</div>
        </div>

        <div
          onClick={() => {
            window.location.href = "#1";
          }}
          style={{ height: "40px", width: "40px", bottom: "0px" }}
          className="bg-light d-flex align-items-center justify-content-center position-absolute rounded-circle translate-middle start-50"
        >
          <lord-icon
            src="https://cdn.lordicon.com/rmkahxvq.json"
            trigger="loop"
          ></lord-icon>
        </div>
      </div>
      <div className="position-relative" id="1">
        {" "}
        <Skills
          lang={lang}
          selectLang={(e) => {
            if (e.value != null) setLang(e.value);
          }}
          value={lang}
        />
        <div
          onClick={() => {
            window.location.href = "#2";
          }}
          style={{ height: "40px", width: "40px", bottom: "0px" }}
          className="bg-light d-flex align-items-center justify-content-center position-absolute rounded-circle translate-middle start-50"
        >
          <lord-icon
            src="https://cdn.lordicon.com/rmkahxvq.json"
            trigger="loop"
          ></lord-icon>
        </div>
      </div>
      <ProjectsPreview lang={lang} />

      <div className="position-relative" id="3">
        <SocialMedia lang={lang} />
        <div
          onClick={() => {
            window.location.href = "#0";
          }}
          style={{ height: "30px", width: "30px", bottom: "0px" }}
          className="bg-light d-flex align-items-center justify-content-center position-absolute rounded-circle translate-middle start-50"
        >
          <lord-icon
            style={{ transform: "rotate(180deg)" }}
            src="https://cdn.lordicon.com/rmkahxvq.json"
            trigger="loop"
          ></lord-icon>
        </div>
      </div>
    </div>
  );
};
export default LandingPage;
