import { Container, Nav, Navbar, Offcanvas } from "react-bootstrap";
import ChooseLang from "./ChooseLang";

const WebsiteNavbar = (props) => {
  return (
    <div
      className="position-absolute z-3 top-0 vw-100"
      style={{ zIndex: "10" }}
    >
    
      <Navbar
        expand={"md"}
        className=" bg-hover bg-dark bg-opacity-50 opacity-100-hover"
        variant="dark"
      >
        <Container variant="dark" fluid>
          <Navbar.Brand href="#">TheDev online</Navbar.Brand>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-md`} />
          <Navbar.Offcanvas
        className="  text-white bg-dark bg-opacity-50 opacity-100-hover"
        id={`offcanvasNavbar-expand-md`}
            aria-labelledby={`offcanvasNavbarLabel-expand-md`}
            placement="end"
          >
            <Offcanvas.Header
              className="bg-dark"
              closeVariant="white"
              closeButton
            >
              <Offcanvas.Title
                id={`offcanvasNavbarLabel-expand-md`}
              ></Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body
              variant="dark"
              className=" "
            >
              <Nav className="justify-content-end flex-grow-1 pe-3">
              <Nav.Link
              onClick={() => {
                window.location.href = "#1";
              }}
            >
              Skills
            </Nav.Link>
            <Nav.Link
              onClick={() => {
                window.location.href = "#2";
              }}
            >
              Service
            </Nav.Link>
            <Nav.Link
              onClick={() => {
                window.location.href = "#3";
              }}
            >
              Social media
            </Nav.Link>
           
              </Nav>
              <ChooseLang
                value={props.value}
                onChange={props.selectLang}
                options={["english", "عربي"]}
              />{" "}
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </div>
  );
};
export default WebsiteNavbar;
